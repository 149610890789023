import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http'
import { Observable } from 'rxjs';

 // Variable de enviroment
import { environment } from '../../../environments/environment'
 // Importacion del Modelo
import { Microred } from '../model/microred.model';

// url de API
const base_url = environment.apiUrl + '/api/microred';
const headers = {'Content-Type':'application/json'}


@Injectable({
    providedIn: 'root'
})
export class MicroredService {
    constructor(private http : HttpClient) { }

    obtenerMicroreds(): Observable<Microred[]> {
        return this.http.get<Microred[]>(`${base_url}/`, { headers });
    }
    crearMicrored(Microred: any): Observable<Microred> {
        return this.http.post<Microred>(`${base_url}/`, Microred, { headers });
    }
    updatedProducto(Microred: any, id: any): Observable<Microred> {
        return this.http.put<Microred>(`${base_url}/` + `id/`, Microred, { headers });
    }
}