import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  public userCurrent :any;

  constructor(public router: Router, private authService: AuthService) {
    this.userCurrent = JSON.parse(localStorage.getItem('user'));
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {}

  logoutFunc() {
    
    const userId = this.userCurrent.id;
    const token = localStorage.getItem('token');
    this.authService.logout(userId, token).subscribe(
      (response) => {
        console.log(response);
        // Realizar acciones adicionales después del logout si es necesario
      },
      (error) => {
        console.error(error);
        // Manejar errores de logout si es necesario
      }
    );
  
  
  this.router.navigateByUrl('auth/login');
}
}
