import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Guardar si el token está presente o no
    const token = localStorage.getItem('token');
    
    if (!token) {
      // Si el token no está presente, redirige a la página de inicio de sesión
      this.router.navigate(['/auth/login']);
      return false; // Devuelve false para bloquear la navegación
    }

    return true; // Permite la navegación si el token está presente
  }
}