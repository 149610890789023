<!-- Page Sidebar Start-->
<div class="logo-wrapper">
	<a routerLink="/">
		<img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
		<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
	</a>
	<div class="back-btn" (click)="sidebarToggle()">
		<i class="fa fa-angle-left"></i>
	</div>
	<div class="toggle-sidebar" (click)="sidebarToggle()">
		<i data-feather="grid" class="status_toggle middle sidebar-toggle"></i>
		<!-- <app-feather-icons icon="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons> -->
	</div>
</div>
<div class="logo-icon-wrapper">
	<a href="javascript:void(0)">
		<img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
	</a>
</div>
<nav class="sidebar-main">
	<div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
		<!-- <i data-feather="arrow-left" ></i> -->
		<app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
	</div>
	<div id="sidebar-menu"
		[ngStyle]="{ marginLeft: this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px' }">
		<ul class="sidebar-links custom-scrollbar">
			<div class="simplebar-wrapper">
				<div class="simplebar-mask">
					<div class="simplebar-offset">
						<div class="simplebar-content-wrapper">
							<div class="simplebar-content">
								<li class="back-btn">
									<a href="javascript:void(0)">
										<img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
									</a>
									<div class="mobile-back text-end" (click)="sidebarToggle()">
										<span>Back</span>
										<i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
									</div>
								</li>

								<li [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'"
									*ngFor="let menuItem of menuItems" [ngClass]="{ active: menuItem.active }">
									<div *ngIf="menuItem.headTitle1">
										<h6 class="lan-1">{{ menuItem.headTitle1 }}</h6>
									</div>

									<label class="badge badge-{{ menuItem.badgeType }}"
										*ngIf="menuItem.badgeType && menuItem.badgeValue">{{ menuItem.badgeValue
										}}</label>
									
									<a href="javascript:void(0)" class="sidebar-link sidebar-title"
										[class.link-nav]="!menuItem.children" [ngClass]="{ active: menuItem.active }"
										*ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
										<!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
										<span>{{ menuItem.title }}</span>
										<div class="according-menu">
											<i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
												*ngIf="menuItem.children"></i>
										</div>
									</a>
									
									<a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
										class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children"
										[ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'link'">
										<!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
										<span>{{ menuItem.title }}</span>
										<div class="according-menu">
											<i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
												*ngIf="menuItem.children"></i>
										</div>
									</a>
									
									<a href="{{ !menuItem.type ? null : menuItem.path }}"
										class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children"
										[ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'extLink'">
										<!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
										<span>{{ menuItem.title
											}}</span>
										<div class="according-menu">
											<i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
												*ngIf="menuItem.children"></i>
										</div>
									</a>
									
									<a href="{{ !menuItem.type ? null : menuItem.path }}" target="_blank"
										class="sidebar-link sidebar-title" [class.link-nav]="!menuItem.children"
										[ngClass]="{ active: menuItem.active }" *ngIf="menuItem.type === 'extTabLink'">
										<!-- <app-svg-icon [icon]="menuItem.icon"></app-svg-icon> -->
										<i class="fa fa-warning" [icon]="menuItem.icon"></i>
										<span>{{ menuItem.title }}</span>
										<div class="according-menu">
											<i class="fa fa-angle-{{ menuItem.active ? 'down' : 'right' }} pull-right"
												*ngIf="menuItem.children"></i>
										</div>
									</a>
									
									<ul class="sidebar-submenu" [ngClass]="{ active: menuItem.active }"
										[ngClass]="{ 'menu-open': menuItem.active, 'menu-close': !menuItem.active }"
										*ngIf="menuItem.children" [style.display]="menuItem.active ? 'block' : 'none'">
										<li *ngFor="let childrenItem of menuItem.children"
											[ngClass]="{ active: childrenItem.active }">
											
											<a class="submenu-title" href="javascript:void(0)"
												*ngIf="childrenItem.type === 'sub'"
												(click)="toggletNavActive(childrenItem)">
												<span> {{ childrenItem.title }}</span>
												<div class="according-menu">
													<i class="fa fa-angle-{{ childrenItem.active ? 'down' : 'right' }} pull-right"
														*ngIf="childrenItem.children"></i>
												</div>
											</a>
											
											<a class="submenu-title"
												[routerLink]="!childrenItem.type ? null : [childrenItem.path]"
												*ngIf="childrenItem.type === 'link'" routerLinkActive="active"
												[routerLinkActiveOptions]="{ exact: true }">
												{{ childrenItem.title }}
												<i class="fa fa-angle-down pull-right"
													*ngIf="childrenItem.children"></i>
											</a>
											
											<a class="submenu-title"
												href="{{ !childrenItem.type ? null : childrenItem.path }}"
												*ngIf="childrenItem.type === 'extLink'" routerLinkActive="active"
												[routerLinkActiveOptions]="{ exact: true }">
												{{ childrenItem.title }}
												<i class="fa fa-angle-down pull-right"
													*ngIf="childrenItem.children"></i>
											</a>
											
											<a class="submenu-title"
												href="{{ !childrenItem.type ? null : childrenItem.path }}"
												target="_blank" *ngIf="childrenItem.type === 'extTabLink'">
												<span>{{ childrenItem.title }}</span>
												<i class="fa fa-angle-down pull-right"
													*ngIf="childrenItem.children"></i>
											</a>
											
											<ul class="nav-sub-childmenu submenu-content" *ngIf="childrenItem.children"
												[ngClass]="{ active: childrenItem.active }"
												[ngClass]="{ 'menu-open': childrenItem.active, 'menu-close': !childrenItem.active }"
												[style.display]="childrenItem.active ? 'block' : 'none'">
												<li *ngFor="let childrenSubItem of childrenItem.children"
													[ngClass]="{ active: childrenSubItem.active }">
													
													<a [routerLink]="!childrenSubItem.type ? null : [childrenSubItem.path]"
														*ngIf="childrenSubItem.type === 'link'"
														routerLinkActive="active"
														[routerLinkActiveOptions]="{ exact: true }">
														{{ childrenSubItem.title }}
													</a>
													
													<a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
														*ngIf="childrenSubItem.type === 'extLink'"
														routerLinkActive="active"
														[routerLinkActiveOptions]="{ exact: true }">
														{{ childrenSubItem.title }}
													</a>
													
													<a href="{{ !childrenSubItem.type ? null : childrenSubItem.path }}"
														target="_blank" *ngIf="childrenSubItem.type === 'extTabLink'">
														{{ childrenSubItem.title }}
													</a>
												</li>
											</ul>
										</li>
									</ul>
								</li>
								
								
								<!-- <li class="sidebar-list" routerLinkActive="active">
									<a class="sidebar-link sidebar-title link-nav" routerLink="/administracion/paciente/crear">
										<h6 class="text-primary"><i class="fa fa-plus-circle"></i> Registrar</h6>
										<div class="according-menu"></div>
									</a>
								</li>
								
								<li class="sidebar-list" routerLinkActive="active">
									<a class="sidebar-link sidebar-title link-nav" routerLink="/administracion/paciente/list">
										<h6><i class="fa fa-users"></i>GESTANTES</h6>
										<div class="according-menu"></div>
									</a>
								</li>
								<li class="sidebar-list">
									<a routerlinkactive="active" class="sidebar-link sidebar-title link-nav"
										href="/single-page">
										<h6><i class="fa fa-warning"></i> EDADES</h6>
										<div class="according-menu"></div>
									</a>
								</li>
								<li class="sidebar-list">
									<a routerlinkactive="active" class="sidebar-link sidebar-title link-nav"
										href="/single-page">
										<i class="fa fa-plus" aria-hidden="true"></i> <span> Crear Gestante</span>
										<div class="according-menu"></div>
									</a>
								</li>
								<li class="sidebar-list">
									<a routerlinkactive="active" class="sidebar-link sidebar-title link-nav"
										href="/single-page">
										<i class="fa fa-plus" aria-hidden="true"></i> <span> Crear Gestante</span>
										<div class="according-menu"></div>
									</a>
								</li>
								<li class="sidebar-list active ">
									<label class="badge badge-light-primary ">2</label><a href="#"
										class="sidebar-link sidebar-title active ">
										<app-svg-icon icon="home"><svg class="stroke-icon ">
												<use href="assets/svg/icon-sprite.svg#stroke-home"></use>
											</svg></app-svg-icon><span>Simple Page</span>
										<div class="according-menu"><i class="fa fa-angle-down pull-right "></i></div>
									</a>
									<ul class="sidebar-submenu menu-open ">
										<li class="">
											<a routerlinkactive="active" class="submenu-title "
												href="/simple-page/first-page"> First Page </a>
										</li>
										<li class="">
											<a routerlinkactive="active" class="submenu-title "
												href="/simple-page/second-page">Second Page</a>
									</ul>
								</li> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</ul>
	</div>
	<div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
		<app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
	</div>
</nav>