import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "administracion",
    loadChildren: () => import("../../pages/administracion/administracion.module").then((m) => m.AdministracionModule),
  },
  {
    path: "consultas",
    loadChildren: () => import("../../pages/consultas/consultas.module").then((m) => m.ConsultasModule),
  },
  {
    path: "dashboard",
    loadChildren: () => import("../../pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "simple-page",
    loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
  },
  {
    path: "single-page",
    loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
  },
  
];
