// auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, tap } from 'rxjs';


 // Variable de enviroment
 import { environment } from '../../../environments/environment'
// url de API
const base_url = environment.apiUrl + '/api';
const headers = { 'Content-Type': 'application/json'};



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  

  constructor(private http: HttpClient) {}

  login(credentials: any): Observable<any> {
    return this.http.post(`${base_url}/login/`, credentials,{headers});
  }

  logout(id: number, token: string): Observable<any> {
    const headersWithToken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }),
    };
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    localStorage.removeItem("ipress");
    localStorage.removeItem("microred");
    localStorage.removeItem("id_paciente");
    localStorage.removeItem("id_bateria");
    localStorage.removeItem("paciente_actual");
   
  
    return this.http.post(`${base_url}/logout/`, { user: id }, headersWithToken);
  }
}