import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    
    {
      title: "Graficos",
      // icon: "home",
      type: "sub",
      badgeType: "light-primary",
      badgeValue: "3",
      active: false,
      children: [
        { path: "/dashboard/circulos", title: "Circulos Estadisticos", type: "link" },
        { path: "/dashboard/barras", title: "Barras Estadisticas", type: "link" },
        { path: "/dashboard/lineas", title: "Lineas del Tiempo", type: "link" },
        { path: "/administracion/paciente/crear", title: "Registrar Gestante", type: "link" },
        { path: "/administracion/paciente/list", title: "Gestantes", type: "link" },
        { path: "/consultas/nuevas", title: "Nuevas Gestantes", type: "link" },
        { path: "/consultas/edades", title: "Por Edad", type: "link" },
        { path: "/consultas/anemicas", title: "Gestantes con Anemia", type: "link" },
        { path: "/consultas/pp", title: "Partos Probables", type: "link" },
        { path: "/administracion/otros", title: "Mas Opciones", type: "link" },
      ],
    },
    { path: "/administracion/paciente/crear", title: "Registrar Gestante",  active: false, type: "link", bookmark: true },
    { path: "/administracion/paciente/list", title: "Gestantes",  active: false, type: "link", bookmark: true },
    { path: "/consultas/nuevas", title: "Nuevas",  active: false, type: "link", bookmark: true },
    { path: "/consultas/edades", title: "Por Edades",  active: false, type: "link", bookmark: true },
    { path: "/consultas/anemicas", title: "Anemia",  active: false, type: "link", bookmark: true },
    { path: "/consultas/pp", title: "Partos Probables",  active: false, type: "link", bookmark: true },
    { path: "/administracion/otros", title: "Mas Opciones",  active: false, type: "link", bookmark: true },
    // { path: "/single-page", title: "PUERPERAS(7SF)",  active: false, type: "link", bookmark: true },
    // { path: "/single-page", title: "VBG",  active: false, type: "link", bookmark: true },
    // { path: "/single-page", title: "Aro",  active: false, type: "link", bookmark: true },
    // { path: "/single-page", title: "Comorbilidades",  active: false, type: "link", bookmark: true },
    // { path: "/single-page", title: "Observaciones",  active: false, type: "link", bookmark: true },
    
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
