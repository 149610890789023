import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
// model
import { Microred } from '../../shared/model/microred.model';
import { Ipress } from '../../shared/model/ipress.model';
// servicios
import { AuthService } from '../../shared/services/auth.service';
import { IpressService } from '../../shared/services/ipress.service';
import { MicroredService } from '../../shared/services/microred.service';

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	public newUser = false;
	// public user: firebase.User;
	public loginForm: FormGroup;
	public show: boolean = false
	public errorMessage: any;
	public submitted = false;

	public microredOptions: Microred[] = [];
	public ipressOptions: Ipress[] = [];
	public selectipress: Ipress[] = [];

	constructor(
		private fb: FormBuilder,
		public router: Router,
		private authService: AuthService,
		private microredService: MicroredService,
		private ipressService: IpressService) {

	}

	// para acceder a las filas de Form
	get f() {
		return this.loginForm.controls;
	}


	ngOnInit() {
		this.get_microred();
		this.get_ipress();
		this.initForm();

		this.loginForm.get('microred_id')?.valueChanges.subscribe((value) => {
			if (value) {
				this.loginForm.get('ipress_id')?.enable();
				this.ipressService.obtenerIpresss().
					subscribe(
						resp => {
							this.ipressOptions = resp.filter(ipress => ipress.microred.toString() === value)
						}
					)
			} else {
				this.loginForm.get('ipress')?.disable();
			}
		});
	}

	initForm(): void {
		this.loginForm = this.fb.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			microred_id: ['', Validators.required],
			ipress_id: [{ value: '', disabled: true }, Validators.required],
		});
	}

	login() {
		this.submitted = true;

		if (this.loginForm.invalid) {
			return
		}

		

		if (this.loginForm.valid) {
			const credentials = this.loginForm.value;
			this.authService.login(credentials).subscribe(
				(response) => {
					localStorage.setItem('token', response.token);
					localStorage.setItem('refresh', response.refresh);
					localStorage.setItem('user', JSON.stringify(response.user));
					localStorage.setItem('ipress', JSON.stringify(response.ipress));
					localStorage.setItem('microred', JSON.stringify(response.microred));
					this.router.navigate(['/dashboard/circulos']);
				},
				error => {
					console.log('error encontrado',error)
					this.errorMessage = error.error.error; // Capturar el mensaje de error
				}
			);
		}
	}


	get_microred() {
		this.microredService.obtenerMicroreds().subscribe(resp => {
			this.microredOptions = resp
		})
	}
	// importamos los servicios de Microred E Ipress
	get_ipress() {
		this.ipressService.obtenerIpresss().subscribe(resp => {
			this.ipressOptions = resp
		})
	}


	showPassword() {
		this.show = !this.show
	}
}
