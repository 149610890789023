import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http'
import { Observable } from 'rxjs';

 // Variable de enviroment
import { environment } from '../../../environments/environment'
 // Importacion del Modelo
import { Ipress } from '../model/ipress.model';

// url de API
const base_url = environment.apiUrl + '/api/ipress';
const headers = {'Content-Type':'application/json'}


@Injectable({
  providedIn: 'root'
})
export class IpressService {
  constructor(private http : HttpClient) { }

  obtenerIpresss(): Observable<Ipress[]> {
    return this.http.get<Ipress[]>(`${base_url}/`, { headers });
  }
  crearIpress(Ipress: any): Observable<Ipress> {
    return this.http.post<Ipress>(`${base_url}/`, Ipress, { headers });
  }
  updatedProducto(Ipress: any, id: any): Observable<Ipress> {
    return this.http.put<Ipress>(`${base_url}/` + `id/`, Ipress, { headers });
  }
}